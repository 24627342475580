import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string;
    isEmailVerify: boolean;
    isSetNewPass: boolean;
    password: any,
    newPasswordVisible: boolean;
    confirmPasswordVisible: boolean;
    otp: any[],
    otpToken: string,
    emailError: string,
    passwordError: string,
    cpassError: string,
    countDown: number,
    isButtonDisabled: boolean,
    otpError: string;
    isPasswordNotifyButtonClicked: boolean;

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    emailVerifyApiCallId: string = "";
    otpVerifyApiCallId: string = "";
    setNewPasswordApiCallId: string = ""
    intervalId: any = null;
    newPasswordApiEndPoint = "bx_block_forgot_password/passwords"
    verifyOtpApiEndPoint = "bx_block_forgot_password/otp_confirmations"
    verifyEmailApiEndPoint = "bx_block_forgot_password/otps"
    errorPasswordNotValid = "Password do not match"
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            isEmailVerify: false,
            isSetNewPass: false,
            password: {
                newPassword: '',
                confirmPassword: ''
            },
            newPasswordVisible: false,
            confirmPasswordVisible: false,
            otp: ['', '', '', ''],
            otpToken: '',
            emailError: '',
            passwordError: '',
            cpassError: '',
            countDown: 30,
            isButtonDisabled: false,
            otpError: '',
            isPasswordNotifyButtonClicked: false,
        }
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    async componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /*istanbul ignore next*/
            if (apiRequestCallId && responseJson) {
                this.emailVerifyResponse(apiRequestCallId, responseJson)
                this.otpVerifyResponse(apiRequestCallId, responseJson) 
                this.setNewPasswordResponse(apiRequestCallId, responseJson)
            }
        }
    // Customizable Area End        
    }

    // Customizable Area Start
    setNewPasswordResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.setNewPasswordApiCallId) {
            /*istanbul ignore next*/
            if (responseJson.data) {
                this.handleClickNotifyUpdateChange()
                this.setState({ isPasswordNotifyButtonClicked: true });
            }
        }
    }

    otpVerifyResponse = (apiRequestCallId: any, responseJson: any) => {
        /*istanbul ignore next*/
        if (apiRequestCallId === this.otpVerifyApiCallId) {
            if (responseJson.messages) {
                this.setState({ otpError: '', isSetNewPass: true })
            }
            if (responseJson.errors) {
                this.setState({ otpError: responseJson.errors[0].otp })
            }
        } 
    }

    emailVerifyResponse = (apiRequestCallId: any, responseJson: any) => {
        /*istanbul ignore next*/
        if (apiRequestCallId === this.emailVerifyApiCallId) {
            if (responseJson.meta) {
                this.setState({ otpToken: responseJson.meta.token, isEmailVerify: true })
            }
            if (responseJson.errors) {
                this.setState({ emailError: responseJson.errors[0].otp })
            }
        }
    }

    onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: e.target.value })
    }

    handleLoginNavigation = () => {
        const login: Message = new Message(getName(MessageEnum.NavigationMessage));
        login.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        login.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(login);
    }

    handleEmailVerify = () => {
        this.setState({ countDown: 30 })
        /*istanbul ignore next*/
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        const { email } = this.state
        let emailError = '';
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let isValid = true;



        if (!email || !emailRegex.test(email)) {

            emailError = 'Invalid email address';

            isValid = false;

        }

        this.setState({ emailError: emailError })
        if (isValid) {
            /*istanbul ignore next*/
            this.intervalId = setInterval(() => {
                this.setState((prevState) => {
                    if (prevState.countDown === 1 && this.intervalId !== null) {
                        clearInterval(this.intervalId);
                        this.intervalId = null;
                        return { countDown: 30, isButtonDisabled: false };
                    }
                    return { countDown: prevState.countDown - 1, isButtonDisabled: true };
                });
            }, 1000);
            this.getValidateOtp()
        }
    }
    emailVerificationBackButton = () => {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        this.setState({ isEmailVerify: false });
    }
    handleContinue = () => {
        this.verifyOtp()
    }
    handleClickNotifyUpdateChange = () => {
        this.setState({ isPasswordNotifyButtonClicked: true });
      }

    handleCloseNotifyModal = () => {
        this.setState({ isPasswordNotifyButtonClicked: false });
      }

    onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        this.setState({ password: { ...this.state.password, [name]: value } })
    }

    handleSignin = () => {
        const { newPassword, confirmPassword } = this.state.password

        let passwordError = '';
        let cpassError = '';
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
        let isValid = true;

        if (!passwordRegex.test(newPassword)) {
            passwordError = this.errorPasswordNotValid
            isValid = false;
        }

        if (!passwordRegex.test(confirmPassword)) {
            cpassError = this.errorPasswordNotValid
            isValid = false;
        }
        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            passwordError = this.errorPasswordNotValid;
            isValid = false;

        }
        this.setState({ passwordError: passwordError, cpassError: cpassError })
        if (isValid) {
            this.setNewPassword()
        }
    }

    handleShowPassword = () => {

        this.setState({
            newPasswordVisible: !this.state.newPasswordVisible,
        });
    };

    handleConfirmPassword = () => {
        this.setState({
            confirmPasswordVisible: !this.state.confirmPasswordVisible,
        });
    }

    handleResendOtp = () => {
        this.setState({ isButtonDisabled: true, countDown: 30 });
        /*istanbul ignore next*/
        this.intervalId = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.countDown === 1 && this.intervalId !== null) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                    return { countDown: 30, isButtonDisabled: false };
                }
                return { countDown: prevState.countDown - 1, isButtonDisabled: true };
            });
        }, 1000);
        this.getValidateOtp();
    }

    getValidateOtp() {
        const headers = {

            "Content-Type": `${configJSON.forgotPasswordAPiContentType}`

        };

        const getEmailVerifyMsg = new Message(

            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.emailVerifyApiCallId = getEmailVerifyMsg.messageId;

        getEmailVerifyMsg.addData(

            getName(MessageEnum.RestAPIResponceEndPointMessage),

            `${this.verifyEmailApiEndPoint}`

        );

        const data = {
            data: {
                attributes: {
                    email: this.state.email
                }
            }
        }

        getEmailVerifyMsg.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(headers)

        );

        getEmailVerifyMsg.addData(

            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(data)

        );

        getEmailVerifyMsg.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.httpPostMethod

        );

        runEngine.sendMessage(getEmailVerifyMsg.id, getEmailVerifyMsg);
    }

    verifyOtp() {
        const headers = {

            "Content-Type": `${configJSON.forgotPasswordAPiContentType}`

        };

        const getOtpVerifyMsg = new Message(

            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.otpVerifyApiCallId = getOtpVerifyMsg.messageId;

        getOtpVerifyMsg.addData(

            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${this.verifyOtpApiEndPoint}`
        );

        const data = {
            data: {
                token: this.state.otpToken,
                otp_code: this.state.otp.join('')
            }
        }

        getOtpVerifyMsg.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(headers)

        );

        getOtpVerifyMsg.addData(

            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(data)

        );

        getOtpVerifyMsg.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.httpPostMethod

        );

        runEngine.sendMessage(getOtpVerifyMsg.id, getOtpVerifyMsg);
    }

    setNewPassword = () => {
        const headers = {

            "Content-Type": `${configJSON.forgotPasswordAPiContentType}`

        };

        const getNewPasswordMsg = new Message(

            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.setNewPasswordApiCallId = getNewPasswordMsg.messageId;

        getNewPasswordMsg.addData(

            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${this.newPasswordApiEndPoint}`
        );

        const body = {
            data: {
                token: this.state.otpToken,
                new_password: this.state.password.newPassword,
                new_password_confirmation: this.state.password.confirmPassword
            }
        }

        getNewPasswordMsg.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(headers)

        );

        getNewPasswordMsg.addData(

            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(body)

        );

        getNewPasswordMsg.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.httpPostMethod

        );

        runEngine.sendMessage(getNewPasswordMsg.id, getNewPasswordMsg);
    }

    optOnChangeHandler = (e: any, index: number) => {
        const { value } = e.target;
        /*istanbul ignore next*/
        if (/^\d?$/.test(value)) {
            const newOtp = [...this.state.otp];
            newOtp[index] = value;
            this.setState({ otp: newOtp }, () => {
                if (value && index < 3) {
                    const nextInput = document.getElementById(`otp-input-${index + 1}`);
                    if (nextInput) nextInput.focus();
                }
            });
        }
    }

    handleKeyDown = (e: any, index: number) => {
        /*istanbul ignore next*/
        if (e.key === 'Backspace' && !this.state.otp[index] && index > 0) {
            const previousInput = document.getElementById(`otp-input-${index - 1}`);
            if (previousInput) previousInput.focus();
        }
    }
    closeModel =()=>{
        this.setState({ isPasswordNotifyButtonClicked: false });
        const login: Message = new Message(getName(MessageEnum.NavigationMessage));
        login.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        login.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(login);
    }
    // Customizable Area End
}
