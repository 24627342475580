import React, { useCallback, useState, useEffect } from "react";
import {
    Button,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Divider,
} from "@mui/material";
import { Add, Remove, Close } from '@mui/icons-material';
import Cropper from "react-easy-crop";

interface myProps {
    image: any;
    onCroppedImage: (croppedImage: any) => void;
    onCloseModal: () => void;
    setFieldValue: any;
}

export default function CropPictureEdit(props: myProps) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [selectedImage, setSelectedImage] = useState<any>();

     useEffect(() => {
        if (props.image instanceof File) {
            const reader = new FileReader();
            reader.onload = () => setSelectedImage(reader.result as string);
            reader.readAsDataURL(props.image);
        } else {
            setSelectedImage(props.image);
        }
    }, [props.image]);

    

    const getCroppedImg = async (imageSrc: string, croppedAreaPixels: any): Promise<File | null> => {
        const image = await new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = imageSrc;
            img.onload = () => resolve(img);
            img.onerror = reject;
        });

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return null;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) return resolve(null);
                const file = new File([blob], "croppedImage.jpg", { type: blob.type });
                resolve(file);
            }, "image/jpeg");
        });
    };

    const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleSave = async () => {
        if (!croppedAreaPixels || !selectedImage) return;

        const croppedImageFile = await getCroppedImg(selectedImage, croppedAreaPixels);

        if (croppedImageFile) {
            props.setFieldValue('photo', croppedImageFile);
            props.onCroppedImage(croppedImageFile);
        }
    };

    const displayedZoom = Math.round((zoom - 1) * 100);

    const handleZoomIn = () => setZoom((prev) => Math.min(prev + 0.1, 2));

    const handleZoomOut = () => setZoom((prev) => Math.max(prev - 0.1, 1));

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader: any = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return <>
        <Dialog open={true} maxWidth="xs" fullWidth>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px",
                    zIndex: 2
                }}
            >
                <Typography
                    variant="h6"
                    fontSize="24px"
                    fontWeight="700"
                    fontFamily="Inter"
                    sx={{ flex: 1, marginLeft: "20px", color: "rgba(0, 0, 0, 1)" }}
                >
                    Crop Image
                </Typography>
                <IconButton data-test-id='handleCloseDialog' onClick={() => props.onCloseModal()}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider sx={{ zIndex: 2 }} />
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px",
                    background: "#FFFFFF",
                    marginTop: "5px",
                    overflowY: "hidden"
                }}
            >
                <Box>
                        <Box sx={{
                            width: 276,
                            height: 276,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                        }}>
                            <Cropper
                                image={selectedImage}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                style={{
                                    containerStyle: {
                                        position: "inherit",
                                        border: "none",
                                    },
                                    mediaStyle: {
                                        position: "sticky",
                                        width: "276px",
                                        height: "276px",
                                        border: "none",
                                    },
                                }}
                            />
                            <Box
                                sx={{
                                    position: "absolute",
                                    width: 276,
                                    height: 276,
                                    borderRadius: "50%",
                                    boxShadow: "0 0 0 10000px rgba(217, 217, 217, 0.4)",
                                    pointerEvents: "none",
                                }}
                            />
                        </Box>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                    sx={{ gap: 2 }}
                >
                    <IconButton
                        data-testid="minus"
                        onClick={() => handleZoomOut()}
                        disabled={zoom <= 1}
                        sx={{
                            color: zoom <= 1 ? "#94A3B8" : "#475569",
                        }}
                    >
                        <Remove />
                    </IconButton>
                    <Typography sx={{ mx: 2, fontFamily: "Inter", fontSize: "14px", color: "#334155", zIndex: 2 }}>
                        {displayedZoom}%
                    </Typography>
                    <IconButton
                        onClick={() => handleZoomIn()}
                        disabled={zoom >= 2}
                        sx={{
                            color: zoom >= 2 ? "#94A3B8" : "#475569",
                        }}
                    >
                        <Add />
                    </IconButton>
                </Box>

                <Box display="flex" flexDirection="column" gap={2} mt={3} width="100%">
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 700,
                            background: "#067B3E",
                            height: "48px",
                            borderRadius: "8px",
                            textTransform: "none",
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <input
                        type="file"
                        id="profile-pic-uploads"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileChange}
                    />

                    <Button
                        variant="text"
                        fullWidth
                        data-test-id="btn1"
                        sx={{
                            background: "#F1F5F9",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#334155",
                            height: "48px",
                            borderRadius: "8px",
                            textTransform: "none",
                            border: "1px solid #E2E8F0",
                        }}
                        onClick={() => {
                            document.getElementById("profile-pic-uploads")?.click();
                        }}
                    >
                        Upload Another
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

const webStyles = {
    root: {
        position: "fixed" as const,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(0, 0, 0, .4)",
        zIndex: 1,
    },
    circularContainer: {
        position: "absolute" as const,
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
};