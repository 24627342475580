import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import {
    FormLabel,
    Grid,
    Box,
    Typography,
    List,
    ListItem,
    Button,
    InputAdornment,
    IconButton,
    TextField,
    ListItemIcon,
    Modal,
    Divider
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Logo, cgOne, cgTwo, cgThree } from './assets'
import CircleIcon from '@mui/icons-material/Circle';
// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController.web";
import FormWrapper from "./components/FormWrapper";

export default class EmailAccountLoginBlock extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    styleOfUi: any = {
        accountButton: {
            padding: '5px 15px',
            borderRadius: '20px',
            border: '1px solid',
            borderColor: 'transparent',
            marginHorizontal: '10px',
        },
        accountUnselected: {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            cursor: 'pointer'
        },
        accountText: {
            fontSize: '16px',
        },
        accountSelectedText: {
            color: 'green',
            fontWeight: 'bold',
        },
        accountUnselectedText: {
            color: 'gray',
        },       
    }

    ImageRender = (img: string) => {
        return (
            <>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img style={{ width: '100vh', height: '100vh' }} src={img} />
                </Grid>
            </>
        )
    }

    renderForgotPasswordContent = () => {
        return (
            <>
                <img src={Logo.default} style={{ height: 'auto', width: '42px', marginBottom: '78px' }} />

                <>
                    <div style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: "18px", fontWeight: 700, lineHeight: "26px" }} >
                            Forget Password
                        </Typography>
                        <Button onClick={this.handleLoginNavigation} sx={{ textTransform: 'none', color: '#1D4ED8', fontFamily: 'Inter,sans-serif', fontSize: "14px", fontWeight: 400, lineHeight: "22px" }}>Back</Button>
                    </div>
                </>

                <View style={{ marginBottom: '20px' }}>
                    <FormLabel sx={{ fontFamily: "Inter", fontSize: "14px", fontWeight: 700, lineHeight: "26px", color: "#334155", marginBottom: "4px" }}>Email</FormLabel>
                    <TextField
                        data-test-id="txtInputEmail"
                        placeholder={"Enter mail id"}
                        value={this.state.email}
                        error={!!this.state.emailError}
                        helperText={this.state.emailError}
                        onChange={this.onChangeText}
                    />
                </View>
                <View
                >
                    <Button
                        data-test-id={"btnEmailLogIn"}
                        sx={{
                            backgroundColor: '#067B3E',
                            '& .MuiButtonBase-root': {
                                backgroundColor: '#067B3E',
                                color: '#ffff'
                            },
                            '&:hover': {
                                backgroundColor: '#067B3E',
                            },
                        }
                        }

                        style={{ color: '#ffff', textTransform: "none", fontFamily: "Inter", fontSize: "16px", lineHeight: "24px", fontWeight: 700, padding: '16px', borderRadius: '8px' }} onClick={this.handleEmailVerify}

                    >Continue</Button>
                    <Box gap={1} sx={styles.mains} >
                        <Typography sx={styles.typo}>Remember Password? </Typography>
                        <span onClick={this.handleLoginNavigation} style={styles.spanTag} data-test-id='upbtn'>Signin now</span>
                    </Box>
                </View>

                <View
                    style={styles.buttonsWrapper}
                >
                </View>
            </>
        )
    }

    renderSetNewPassword = () => {
        return (
            <>
                <FormWrapper>
                    {this.renderSetNewPasswordContent()}
                </FormWrapper>
                {this.ImageRender(cgThree.default)}

            </>
        )
    }

    renderSetNewPasswordContent = () => {
        return (
            <>
                <img src={Logo.default} style={{ height: 'auto', width: '42px', marginBottom: '78px' }} />

                <>
                    <div style={{ paddingBottom: '20px' }}>
                        <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: "18px", fontWeight: 700, lineHeight: "26px" }} >
                            Reset Password
                        </Typography>
                    </div>
                </>

                <Box>
                    <label style={formStyle.formlable}>Password</label>
                    <TextField
                        FormHelperTextProps={{
                            sx: { mx: '0', px: '0' },


                            component: 'span',
                        }}
                        style={formStyle.inputStyle}
                        placeholder="Enter password"
                        variant="outlined"
                        name="newPassword"

                        value={this.state.password.newPassword}
                        onChange={this.onChangePassword}
                        error={!!this.state.passwordError}
                        helperText={this.state.passwordError}
                        size={'small'}
                        /*istanbul ignore next*/
                        type={this.state.newPasswordVisible ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        data-test-id="passwordField"
                                        onClick={this.handleShowPassword}
                                        edge="end"
                                    >
                                        {/*istanbul ignore next*/}
                                        {this.state.newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>

                            ),
                        }}
                    />

                </Box>
                {this.renderPasswordFormat()}
                <Box sx={{ marginBottom: '10px' }}>
                    <label style={formStyle.formlable}>Confirm password</label>
                    <TextField
                        FormHelperTextProps={{
                            sx: { mx: '0', px: '0' },
                            component: 'span',
                        }}
                        style={formStyle.inputStyle}
                        placeholder="Confirm password"
                        variant="outlined"
                        name="confirmPassword"
                        type={this.state.confirmPasswordVisible ? "text" : "password"}
                        value={this.state.password.confirmPassword}
                        onChange={this.onChangePassword}
                        error={!!this.state.cpassError}
                        helperText={this.state.cpassError}
                        size={'small'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        data-test-id="confirmPasswordField"
                                        onClick={this.handleConfirmPassword}
                                        edge="end"
                                    >
                                        {this.state.confirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <View
                >
                    <Button
                        data-test-id={"btnEmailLogIn"}
                        sx={{
                            backgroundColor: '#067B3E',
                            '& .MuiButtonBase-root': {
                                backgroundColor: '#067B3E',
                                color: '#ffff'
                            },
                            '&:hover': {
                                backgroundColor: '#067B3E',
                            },
                        }
                        }

                        style={{ color: '#ffff', textTransform: "none", fontFamily: "Inter", fontSize: "16px", lineHeight: "24px", fontWeight: 700, padding: '16px', borderRadius: '8px' }}

                        onClick={this.handleSignin}>Continue</Button>
                    <Box gap={1} sx={styles.mains} >
                        <Typography sx={styles.typo}>Remember Password? </Typography>
                        <span onClick={this.handleLoginNavigation} style={styles.spanTag} data-test-id='upbtn'>Signin now</span>
                    </Box>
                </View>

                <View
                    style={styles.buttonsWrapper}
                >
                </View>
            </>
        )
    }

    renderOtp = () => {
        return (
            <>
                <FormWrapper>
                    {this.renderOtpContent()}
                </FormWrapper>
                {this.ImageRender(cgTwo.default)}
            </>
        )
    }

    renderForgotPassword = () => {
        return (
            <>
                <FormWrapper>
                    {this.renderForgotPasswordContent()}
                </FormWrapper>
                {this.ImageRender(cgOne.default)}
            </>
        )
    }

    renderPasswordFormat = () => {
        return (
            <List sx={{ marginBottom: '24px' }}>
                <ListItem>
                    <ListItemIcon sx={{ minWidth: 10, marginRight: '5px' }}>
                        <CircleIcon sx={{ fontSize: "9px", color: '#334155' }} />
                    </ListItemIcon>
                    <Typography sx={formStyle.newPasswordText}>Uppercase letters: A-Z</Typography>
                </ListItem>
                <ListItem>
                    <ListItemIcon sx={{ minWidth: 10, marginRight: '5px' }}>
                        <CircleIcon sx={{ fontSize: "9px", color: '#334155' }} />
                    </ListItemIcon>
                    <Typography sx={formStyle.newPasswordText}>Lowercase letters: a-z</Typography>
                </ListItem>
                <ListItem>
                    <ListItemIcon sx={{ minWidth: 10, marginRight: '5px' }}>
                        <CircleIcon sx={{ fontSize: "9px", color: '#334155' }} />
                    </ListItemIcon>
                    <Typography sx={formStyle.newPasswordText}>Numbers: 0-9</Typography>
                </ListItem>
                <ListItem>
                    <ListItemIcon sx={{ minWidth: 10, marginRight: '5px' }}>
                        <CircleIcon sx={{ fontSize: "9px", color: '#334155' }} />
                    </ListItemIcon>
                    <Typography sx={formStyle.newPasswordText}>Special character</Typography>
                </ListItem>
            </List>
        )
    }

    renderOtpContent = () => {
        return (
            <>
                <img src={Logo.default} style={{ height: 'auto', width: '42px', marginBottom: '78px' }} />
                <>
                    <div style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: "18px", fontWeight: 700, lineHeight: "26px" }} >
                            Email verification
                        </Typography>
                        <Button onClick={this.emailVerificationBackButton} sx={{ textTransform: 'none', color: '#1D4ED8', fontFamily: 'Inter,sans-serif', fontSize: "14px", fontWeight: 400, lineHeight: "22px" }}>Back</Button>
                    </div>
                </>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, fontFamily: 'Inter,sans-serif', color: '#1E293B' }}>{this.state.email}</Typography>
                </Box>

                <Box display="flex" gap={3} width={'248px'} height={'44px'} alignSelf={'center'} marginBottom={'15px'}>
                    {this.state.otp.map((_, index: number) => (
                        <TextField
                            key={index}
                            id={`otp-input-${index}`}
                            value={this.state.otp[index]}
                            onChange={(event) => this.optOnChangeHandler(event, index)}
                            onKeyDown={(event) => this.handleKeyDown(event, index)}
                            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                            variant="outlined"
                            size="small"
                            style={{ width: '50px' }}
                        />
                    ))}
                </Box>
                <Typography sx={{ padding: '10px 0', alignSelf: 'center', color: '#1D4ED8', fontFamily: 'Inter,sans-serif', fontSize: "14px", fontWeight: 400, lineHeight: "22px" }}><span style={{ cursor: 'pointer' }} onClick={this.state.isButtonDisabled ? () => { } : this.handleResendOtp}>{this.state.isButtonDisabled ? `Resend OTP in ${this.state.countDown} secs` : `Resend OTP`}</span></Typography>
                <Typography sx={{ alignSelf: 'center', color: '#CC3333', fontFamily: 'Inter,sans-serif', fontSize: '12px', marginBottom: '12px' }}>{this.state.otpError}</Typography>
                <View>
                    <Button
                        data-test-id={"btnEmailLogIn"}
                        sx={{
                            backgroundColor: '#067B3E',
                            '& .MuiButtonBase-root': {
                                backgroundColor: '#067B3E',
                                color: '#ffff'
                            },
                            '&:hover': {
                                backgroundColor: '#067B3E',
                            },
                        }
                        }
                        onClick={this.handleContinue}

                        style={{ color: '#ffff', textTransform: "none", fontFamily: "Inter", fontSize: "16px", lineHeight: "24px", fontWeight: 700, padding: '16px', borderRadius: '8px' }}

                    >Continue</Button>
                    <Box gap={1} sx={styles.mains}>
                        <Typography sx={styles.typo}>Remember Password? </Typography>
                        <span onClick={this.handleLoginNavigation} style={styles.spanTag} data-test-id='upbtn'>Signin now</span>
                    </Box>
                </View>

                <View
                    style={styles.buttonsWrapper}
                >
                </View>
            </>
        )
    }

    render() {
        return (
            // Customizable Area End Start
            <>
                <Modal
                    open={this.state.isPasswordNotifyButtonClicked}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "450px", backgroundColor: "white", borderRadius: "8px" }}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} padding={"24px 16px 24px 40px"}>
                            <Typography sx={{ fontSize: 24, fontWeight: 700, fontFamily: "Inter, sans-serif", color: "black"}}>
                                Success
                            </Typography>
                        </Box>
                        <Divider />
                        <Box padding={"0px 40px"} margin={"24px 0px"}
                            height={'100px'}>
                            <Box width={'100%'} display={"flex"} justifyContent={'center'}
                            >
                                <Typography sx={{ fontSize: 18, fontWeight: 400, fontFamily: "Inter", color: "black", mt:4 }}>
                                    Password has been updated successfully.
                                </Typography>
                            </Box>
                        </Box>
                        <Box padding={"24px"} marginBottom={"30px"}>
                            <Button
                                data-test-id='confirm-orders-button'
                                fullWidth
                                sx={{
                                    cursor: "pointer", borderRadius: '8px', backgroundColor: '#067B3E', textTransform: 'none', color: 'white', padding: "10px 0px", fontWeight: '700', fontSize: '16px', fontFamily: "Inter",
                                    '&:hover': { backgroundColor: '#067B3E', color: 'white' }
                                }} onClick={this.closeModel}
                            >
                                Okay
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    {!this.state.isSetNewPass && this.state.isEmailVerify ? this.renderOtp() : !this.state.isSetNewPass && this.renderForgotPassword()}
                    {this.state.isSetNewPass && this.renderSetNewPassword()}
                </Grid>

            </>
            // Customizable Area End
        );
    }

}

const formStyle = {
    inputStyle: {
        marginTop: "5px",
        width: "100%",
        fontSize: '16px',
        LineWeight: 400,
    },
    btnstyle: {
        BackgroundColor: '#067B3E',
        width: '100%'
    },
    formlable: {
        fontFamily: 'Inter,sans-serif',
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '15px',
        lineHeight: '22px',
        color: '#334155'
    },
    newPasswordText: {
        fontFamily: 'Inter,sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        color: '#334155',
        lineHeight: '18px',
    }
}

const styles = StyleSheet.create({
    main: {
        display: "flex",
        alignItems: "center"
    },
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        width: "50%",
    },
    textFont: {
        fontFamily: "Helvetica"
    },
    formWrapper: {
        display: "flex",
        width: "100%",
        paddingTop: "20px"
    },
    inputWrapper: {
        flex: 1,
        padding: "20px"
    },
    removeBackground: {
        backgroundColor: "transparent"
    },
    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
    buttonsWrapper: {
        flex: 1,
        padding: "10px",
    },
    OrWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
    },
    typo: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: '400'
    },
    mains: {
        display: "flex",
        justifyContent: "center", 
        alignItems: "center", 
        marginTop: "21px", 
    },
    spanTag : {
        color:'#067B3E',
        fontWeight: '700', 
        fontFamily: "Inter",
        fontSize: 14,
    }
});