import React, { ReactNode } from 'react';
import { Box, Grid, Paper } from '@mui/material';

// Define prop types for the Layout component
interface LayoutProps {
  backgroundImage?: string; // Optional background image URL
  children?: ReactNode; // This allows us to use JSX between <Layout> tags
}

// Layout Component
const SideImgLayout: React.FC<LayoutProps> = ({ children, backgroundImage = "https://cannabismuseum-amsterdam.com/wp-content/uploads/2023/08/Cannabis-Clones.jpg" }) => {
  return (
    <Grid container sx={{ height: '100vh' }}>
        {/* Right Section for form or any JSX content */}
      <Grid
        item
        xs={12}
        md={6}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        {children}
      </Grid>
      {/* Left Section with background image */}
      <Grid
        item
        xs={0}
        md={6}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      
    </Grid>
  );
};

export default SideImgLayout;
