Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.userPatchApi ='account_block/accounts/'
exports.getOrderAPIEndPoint = "bx_block_order_management/orders/account_order_item_list?page=1&per_page=10"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End