import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";

interface AccountAttributes {
  address: string | null;
  business_description: string | null;
  contact_name: string;
  email: string;
  phone_number: number | null;
  type: string;
  county: string;
  state: string;
  license_number: number | null;
  name_of_business: string;
  unique_auth_id: string;
  created_at: string;
  updated_at: string;
  email_verified: boolean;
  subscribed: boolean;
  start_date: string;
  end_date: string;
  unsubscribed: boolean;
  subscription_expiration: string | null;
  photo_url: string | null;
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountResponse {
  data: AccountData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  cartCount?: number;
  onSearchChange?: (value: string) => void;
  searchFieldValue?: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  drawerItems: any;
  userAttributes: AccountAttributes;
  isBuyer: boolean;
  anchorEl: HTMLButtonElement | null
  notificationList: any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDetailsApiCallID: string = "";
  NotifyListHndlerApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      drawerItems: [],
      userAttributes: {
        address: null,
        business_description: null,
        contact_name: "",
        email: "",
        phone_number: null,
        type: "",
        county: "",
        state: "",
        license_number: null,
        name_of_business: "",
        unique_auth_id: "",
        created_at: "",
        updated_at: "",
        email_verified: false,
        subscribed: false,
        start_date: "",
        end_date: "",
        unsubscribed: false,
        subscription_expiration: null,
        photo_url: null,
      },
      notificationList: [],
      isBuyer: false,
      anchorEl: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallID === this.getUserDetailsApiCallID){
      this.handleResponseOfLoggedInUser(responseJson);
    }
    /*istanbul ignore next*/
    if (apiRequestCallID === this.NotifyListHndlerApiCallId) {
      if (responseJson.meta) {
          this.setState({ notificationList: responseJson?.data })
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
      this.getTokenFromLocalStorage();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.getLoggendInUserData(token);
  };

  getLoggendInUserData = (token : string) => {
    const headers = {
      "Content-Type": `${configJSON.validationApiContentType}`,
      "token": token
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiCallID = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UserInfoApi}`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  }

  handleResponseOfLoggedInUser = (responseJson : AccountResponse) => {
    this.setState({ userAttributes: responseJson.data.attributes })
    this.setState({isBuyer:responseJson.data.attributes.type === "BuyerAccount"})
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.props.onSearchChange?.(value);
  };

  handleNavigationToProduct = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Filteritems");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigationToProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigationToShoppingCart = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ShoppingCartOrders");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  /*istanbul ignore next*/
  async getNotificationListHandler() {
    const token = await getStorageData('authToken');

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.NotifyListHndlerApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getNotificationAPIEndPoint}`
    );

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

    /*istanbul ignore next*/
  handleClick = async(event: any) => {
    this.setState({anchorEl: event.currentTarget})
    await this.getNotificationListHandler()
  }

  /*istanbul ignore next*/
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  // Customizable Area End
}
