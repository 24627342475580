import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import {
  SelectChangeEvent,
} from "@mui/material";

interface AccountAttributes {
  address: string | null;
  business_description: string | null;
  contact_name: string;
  email: string;
  phone_number: number;
  type: string;
  county: string;
  state: string;
  license_number: number;
  name_of_business: string;
  unique_auth_id: string;
  created_at: string;
  updated_at: string;
  email_verified: boolean;
  subscribed: boolean;
  start_date: string;
  end_date: string;
  unsubscribed: boolean;
  subscription_expiration: string | null;
  photo_url: string | null;
}

interface CartItem {
  id: number;
  title: string;
  sub_title: string;
  price: number;
  quantity: number;
  product_id: number;
  seller_acc_id: string;
  image_url: string;
  order_management_order_id: number | null;
  status: string | null;
  available_quantity: number;
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountResponse {
  data: AccountData;
}

interface ProductSpecification {
  id: number;
  key: string;
  value: string;
  product_id: number;
  created_at: string;
  updated_at: string;
}

interface ProductImage {
  id: number;
  url: string;
}

interface ProductAttributes {
  id: number;
  name: string;
  product_feature: string | null;
  stock: number;
  price: string;
  type: string;
  classification: string;
  account_id: number;
  min_quantity: number;
  available_quantity: number;
  images: ProductImage[] | null;
  business_name: string;
  specifications_attributes: ProductSpecification[];
  description: string | null;
  average_rating: number;
  comments_and_ratings: any[];
}

interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface ProductData {
  data: Product[];
  meta:{
    current_page: number,
    total_pages: number,
    total_count: number
  }
}

interface FilterState {
  type: string[];
  breeder: string[];
  strains: string[];
  county: string[];
  priceRange: number[]
  sort_by: string[];
}

interface ProductTypeResponse {
  message: string;
  types: {
    seeds: number;
    teens: number;
    clones: number;
    breeders_cuts: number;
  };
  status: string;
}

interface BreedersResponse {
  breeders: string[];
}

interface CountiesResponse {
  counties: string[];
}

interface StrainsResponse {
  strains: string[];
}

type FilterOption = {
  value: string;
};

interface FilterOptionsState {
  type: FilterOption[];
  breeder: FilterOption[];
  strains: FilterOption[];
  county: FilterOption[];
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  isBuyerAccount: boolean;
  viewMode: string;
  products: Product[];
  page: number;
  per_page: number;
  totalProducts: number;
  totalProductsAfterFilter: number;
  totalProductsAfterSearch: number;
  isFilterSuccessfullyApplied: boolean;
  isSearchFilterSuccessfullyApplied: boolean;
  filter: FilterState;
  filterOptions: FilterOptionsState;
  pageForAdvancedSearch: number;
  perPageForAdvancedSearch: number;
  pageForSearch:number;
  perPageForSearch: number;
  quantities: number[];
  loading: boolean;
  cartCount: number;
  searchValue: string;
  isSearched: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilterItemsBlockWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDetailsApiCallID: string = "";
  getProductListApiCallId: string = "";
  deleteProductApiCallId: string = "";
  apiProductAddToCartApiCallId: string = "";
  getTypeApiCallId: string = "";
  getStrainsApiCallId: string = "";
  getCountiesApiCallId: string = "";
  getBreedersApiCallId: string = "";
  filterProductsApiCall: string = "";
  getCartOrdersCallId: string = "";
  filterProductsApiCallBySearch: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      token:"",
      isBuyerAccount: false,
      viewMode: "grid",
      products: [],
      page: 1,
      per_page: 12,
      totalProducts: 0,
      totalProductsAfterFilter: 0,
      totalProductsAfterSearch: 0,
      isFilterSuccessfullyApplied: false,
      isSearchFilterSuccessfullyApplied: false,
      filter: {
        type: [],
        breeder: [],
        strains: [],
        county: [],
        priceRange: [0, 1000],
        sort_by: [],
      },
      filterOptions: {
        type: [],
        breeder: [],
        strains: [],
        county: [],
      },
      pageForAdvancedSearch: 1,
      perPageForAdvancedSearch: 12,
      pageForSearch: 1,
      perPageForSearch: 12,
      quantities: [],
      loading: false,
      cartCount: 0,
      searchValue: "",
      isSearched: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallID) {
      case this.getUserDetailsApiCallID:
        this.handleResponseOfLoggedInUser(responseJson);
        break;
      
      case this.getProductListApiCallId:
        this.handleResponseOfProductList(responseJson);
        break;
    
      case this.deleteProductApiCallId:
        this.handleResponseOfProductDelete();
        break;
    
      case this.apiProductAddToCartApiCallId:
        this.handleResponseOfAddToCart();
        break;

      case this.getTypeApiCallId:
        this.handleResponseOfTypeList(responseJson);
        break;

      case this.getBreedersApiCallId:
        this.handleResponseOfBreedersList(responseJson);
        break;

      case this.getStrainsApiCallId:
        this.handleResponseOfStrainsList(responseJson);
        break;

      case this.getCountiesApiCallId:
        this.handleResponseOfCountiesList(responseJson);
        break;

      case this.filterProductsApiCall:
        this.handleResponseOfFilterProductList(responseJson);
        break; 

      case this.filterProductsApiCallBySearch:
        this.handleResponseOfSearchProductList(responseJson);
        break; 

      case this.getCartOrdersCallId:
        const filteredCartItems = responseJson?.cart_items?.filter(
          (item: CartItem) => item.order_management_order_id === null
        );
        this.setState({ cartCount: filteredCartItems?.length });
        break; 
    
      default:
        break;
    }
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.getTokenFromLocalStorage();
    let businessName = new URLSearchParams(window.location.search).get('navigationBarTitleText');
    if(businessName) {
      this.handleClickApplyFilterButton()
      const label = 'Breeder'
      this.setState((prevState) => {
        const key = label.toLowerCase() as keyof FilterState;
        const newValues = [...new Set([...prevState.filter[key], businessName])];
    
        return {
          filter: {
            ...prevState.filter,
            [key]: newValues,
          },
        };
      });
    }
    return Promise.resolve();
  }

  getTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.getLoggendInUserData(token);
    /*istanbul ignore next*/
    let businessName = new URLSearchParams(window.location.search).get('navigationBarTitleText');
    /*istanbul ignore next*/
    if(!businessName){
      this.getProductList(token);
    }
    this.getTypeList(token);
    this.getBreedersList(token);
    this.getStrainsList(token);
    this.getCountiesList(token);
    this.getCartOrders(token);
  };

  getCartOrders = (token : string) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartOrdersCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_shopping_cart/order_items'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLoggendInUserData = (token : string) => {
    const headers = {
      "Content-Type": `${configJSON.validationApiContentType}`,
      "token": token
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiCallID = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UserInfoApi}`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  }

  getProductList = (token: string) => {
    const{ page, per_page } = this.state;
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}?page=${page}&per_page=${per_page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTypeList = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventory_management/products/product_type_values"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBreedersList = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBreedersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventory_management/products/list_breeders"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStrainsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStrainsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventory_management/products/list_strains"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountiesList = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventory_management/products/list_counties"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const { token } = this.state;
    this.setState({ page: value }, () => {
      this.getProductList(token);
    });
  };

  handlePageChangeAfterFilter = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ pageForAdvancedSearch: value }, () => {
      this.handleFetchFilterRecords();
    });
  };

  handlePageChangeAfterSearchFilter = (event: React.ChangeEvent<unknown>, value: number) => {
    const{ searchValue } = this.state;
    this.setState({ pageForSearch: value }, () => {
      this.handleFetchFilterRecordsBySearch(searchValue);
    });
  };

  handleResponseOfLoggedInUser = (responseJson : AccountResponse) => {
    this.setState({
      isBuyerAccount : responseJson.data.attributes.type === "BuyerAccount"
    })
    this.saveLoggedInUserData(responseJson.data)
  };

  handleResponseOfProductList = (responseJson : ProductData) => {
    if(responseJson.data && responseJson.data.length > 0){
      this.setState({
        products : responseJson.data,
        quantities: responseJson.data.map((product) => product.attributes.min_quantity),
        totalProducts: responseJson.meta.total_count,
        loading : false,
      })
    }
    else{
      this.setState({
        products : [],
        quantities: [],
        totalProducts: 0,
        loading : false,
      })
    }
  };

  handleResponseOfFilterProductList = (responseJson : ProductData) => {
    this.setState({
      products : responseJson.data,
      quantities: responseJson.data.map((product) => product.attributes.min_quantity),
      isFilterSuccessfullyApplied: true,
      totalProductsAfterFilter: responseJson.meta.total_count,
      loading : false,
    })
  };

  handleResponseOfSearchProductList = (responseJson: ProductData) => {
    if(responseJson.data && responseJson.data.length > 0){
      this.setState({
        products : responseJson.data,
        quantities: responseJson.data.map((product) => product.attributes.min_quantity),
        isSearchFilterSuccessfullyApplied: true,
        totalProductsAfterSearch: responseJson.meta.total_count,
        loading : false,
      })
    }
    else{
      this.setState({
        products : [],
        quantities: [],
        isSearchFilterSuccessfullyApplied: true,
        totalProductsAfterSearch: 0,
        loading : false,
      })
    }
  };

  handleResponseOfProductDelete = () => {
    const { token } = this.state;
    this.getProductList(token);
  };

  handleResponseOfAddToCart = () => {
    const { products, token } = this.state;
    this.setState({ quantities: products.map((product) => product.attributes.min_quantity), loading: false })
    this.getCartOrders(token);
  };

  handleResponseOfTypeList = (responseJson : ProductTypeResponse) => {
    const typeOptions = Object.keys(responseJson.types).map((key) => ({ value: key }));
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        type: typeOptions,
      },
    }));
  };

  handleResponseOfBreedersList = (responseJson : BreedersResponse) => {
    const breederOptions = responseJson.breeders.map((breeder) => ({ value: breeder }));
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        breeder: breederOptions,
      },
    }));
  };

  handleResponseOfStrainsList = (responseJson : StrainsResponse) => {
    const strainOptions = responseJson.strains.map((strains) => ({ value: strains }));
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        strains: strainOptions,
      },
    }));
  };

  handleResponseOfCountiesList = (responseJson : CountiesResponse) => {
    const countieOptions = responseJson.counties.map((counties) => ({ value: counties }));
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        county: countieOptions,
      },
    }));
  };

  saveLoggedInUserData = async (data: AccountData) => {
    await setStorageData('user', JSON.stringify(data))
  }

  handleViewChange = (mode: string) => {
    const { token, isFilterSuccessfullyApplied, isSearchFilterSuccessfullyApplied } = this.state;
    this.setState({ viewMode: mode });
    if(isFilterSuccessfullyApplied){
      this.setState({ pageForAdvancedSearch: 1 }, () => {
        this.handleFetchFilterRecords();
      });
    }
    else if(isSearchFilterSuccessfullyApplied){
      this.setState({ pageForSearch: 1 }, () => {
        this.handleFetchFilterRecordsBySearch(this.state.searchValue);
      });
    }
    else{
      this.setState({ page: 1 }, () => {
        this.getProductList(token);
      });
    }
  };

  handlePriceRangeChange = (event: Event, newValue: number | number[]) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        priceRange: newValue as number[],
      },
    }));
  };  

  handleSelectChange = (event: SelectChangeEvent<unknown>, label: string) => {
    const value = event.target.value as string;
    
    this.setState((prevState) => {
      const key = label.toLowerCase() as keyof FilterState;
      const newValues = [...new Set([...prevState.filter[key], value])];
  
      return {
        filter: {
          ...prevState.filter,
          [key]: newValues,
        },
      };
    });
  };
  
  handleDeleteChip = (label: string, value: string | number) => {
    const initialFilterState = {
      type: [],
      breeder: [],
      strains: [],
      county: [],
      priceRange: [0, 1000],
      sort_by: [],
    };
  
    this.setState(
      (prevState) => {
        const key = label.toLowerCase() as keyof FilterState;
        const updatedArray = [...prevState.filter[key]];
        const indexToRemove = updatedArray.indexOf(value);
  
        if (indexToRemove > -1) {
          updatedArray.splice(indexToRemove, 1);
        }
  
        return {
          filter: {
            ...prevState.filter,
            [key]: updatedArray,
          },
          pageForAdvancedSearch: 1,
        };
      },
      () => {
        const { filter, isFilterSuccessfullyApplied, token } = this.state;
        const isFilterReset = JSON.stringify(filter) === JSON.stringify(initialFilterState);
        if (isFilterReset && isFilterSuccessfullyApplied) {
          this.getProductList(token);
          this.setState({ isFilterSuccessfullyApplied: false, isSearchFilterSuccessfullyApplied: false, searchValue: "" });
        } else if (isFilterSuccessfullyApplied) {
          this.handleFetchFilterRecords();
        }
      }
    );
  };
   
  handleDeletePriceChip = () => {
    const initialFilterState = {
      type: [],
      breeder: [],
      strains: [],
      county: [],
      priceRange: [0, 1000],
      sort_by: [],
    };
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          priceRange: [0, 1000],
        },
        pageForAdvancedSearch: 1,
      }),
      () => {
        const { filter, isFilterSuccessfullyApplied, token } = this.state;
        const isFilterReset = JSON.stringify(filter) === JSON.stringify(initialFilterState);
        if (isFilterReset && isFilterSuccessfullyApplied) {
          this.getProductList(token);
          this.setState({ isFilterSuccessfullyApplied: false, isSearchFilterSuccessfullyApplied: false, searchValue: "" });
        } else if (isFilterSuccessfullyApplied) {
          this.handleFetchFilterRecords();
        }
      }
    );
  };
  
  clearAllFilters = () => {
    const{ token } = this.state;
    this.setState({
      filter: {
        type: [],
        breeder: [],
        strains: [],
        county: [],
        priceRange: [0, 1000],
        sort_by: [],
      },
    });
    if(this.state.isFilterSuccessfullyApplied) {
      this.setState({ page: 1, isFilterSuccessfullyApplied: false, isSearchFilterSuccessfullyApplied: false }, () => {
        this.getProductList(token);
      });
    }
  };

  handleDeleteProduct = (productId : number) => {
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddProduct = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EditProduct");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleEditProduct = (id: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `EditProduct`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), String(id))
    this.send(message);
  }
  
  handleNavigation = (id: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ProductDiscription');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), String(id))
    this.send(message);
}

  handleAddToCartButton = (productId : number,quantity : number) => {
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const order_items = {
      product_id: productId,
      quantity: quantity,
    }
    const body = {
      order_items: order_items,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProductAddToCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAddToCartAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickApplyFilterButton = (type?: string) => {
    this.setState({ pageForAdvancedSearch: 1, searchValue: "" }, () => {
      this.handleFetchFilterRecords(type);
    });
  }

  handleFetchFilterRecords = async(filterType?: string) => {
    const { token, filter, pageForAdvancedSearch, perPageForAdvancedSearch } = this.state;
    const { type, breeder, county, sort_by, strains, priceRange } = filter;

    const authToken = await getStorageData("authToken");

    let businessName = new URLSearchParams(window.location.search).get('navigationBarTitleText');

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token || authToken
    };

    const body = {
      type: filterType,

      types: type,
      breeders: breeder || [`${businessName}`],
      counties: county,
      sort_by: sort_by,
      strains: strains,
      min_price: priceRange[0],
      max_price: priceRange[1],
      page: pageForAdvancedSearch,
      per_page: perPageForAdvancedSearch,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterProductsApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventory_management/products/search"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchChange = (value: string) => {
    this.setState({ searchValue: value });
    if(value.length >= 3){
      this.setState({
        filter: {
          type: [],
          breeder: [],
          strains: [],
          county: [],
          priceRange: [0, 1000],
          sort_by: [],
        },
        isFilterSuccessfullyApplied: false,
        isSearched: true,
      });
      this.handleFetchFilterRecordsBySearch(value);
    }
    else{
      if(this.state.isSearched){
        this.setState({ page: 1,isSearched: false, isSearchFilterSuccessfullyApplied: false }, () => {
          this.getProductList(this.state.token);
        });
      }
    }
  };

  handleFetchFilterRecordsBySearch = (value : string) => {
    const { token, pageForSearch, perPageForSearch } = this.state;
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterProductsApiCallBySearch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_filter_items/filtering?value=${value}&per_page=${perPageForSearch}&page=${pageForSearch}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
