import React from "react";
// Customizable Area Start
import { Typography, Avatar, Box, Popover, Accordion, AccordionSummary } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End
import NotificationController, { Props } from "./NotificationController.web";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class Notification extends NotificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTextContent =(item:any)=>{
        return(
            /*istanbul ignore next*/
            this.state.expanded === item?.id || (item?.contents.length || 0) <= 50
                ? item?.contents
                : `${item?.contents.slice(0, 50)}`
        )
    }
    renderNotification=(item:any)=>{
        return (
            <Box sx={{ padding: '16px 0', borderBottom: "1px solid #e0e0e0" }} key={item?.attributes?.id}>
                <Box display="flex" alignItems="center">
                    <Avatar src={item?.attributes?.profile_pic || item?.attributes?.first_letter} sx={{ width: 56, height: 56, marginRight: 2, color: '#007A2F', backgroundColor: '#E7FFF1' }}/>
                    <Box flex="1" sx={{ borderBottom: '1px solid #e0e00e0' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography sx={{ fontWeight: 500, cursor: 'pointer' }}><span onClick={
                                /*istanbul ignore next*/
                                () => this.handleNotificationClick(item?.attributes?.id)
                            }
                            >{item?.attributes?.headings}</span></Typography>
                            <Typography variant="caption" color="text.secondary">{item?.attributes?.created_ago}</Typography>
                        </Box>
                        <Accordion elevation={0} expanded={this.state.expanded === item?.attributes?.id ? true : false} onChange={

                        /*istanbul ignore next*/
                            ()=>this.handleToggle(item?.attributes?.id)
                            } disableGutters sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', '&::before': { display: "none" } }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ marginLeft: '10px' }} />} aria-controls="panel-content" id="panel-header">
                                <Typography variant="body2" color="text.secondary" sx={{ ml: '-14px' }}>
                                    {this.renderTextContent(item?.attributes)}
                                </Typography>
                            </AccordionSummary>
                        </Accordion>
                    </Box>
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { notificationList } = this.state

        return (
            <>
                <Box>
                    <Popover
                        id={this.props.id}
                        open={this.props.open || false}
                        anchorEl={this.props.anchorEl}
                        onClose={this.handleCloseNotify}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={
                            {
                                sx: {
                                    width: '500px',
                                    padding: '17px 14px',
                                    borderRadius: '8px',
                                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                                }
                            }
                        }
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', borderBottom: '1px solid #e0e0e0' }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>Notifications</Typography>
                            <CloseIcon onClick={this.handleCloseNotify} sx={{ cursor: 'pointer' }} />
                        </Box>
                        {notificationList.length > 0 ? <>{notificationList?.map((item: any) => 
                            this.renderNotification(item)
                        )}</> : <Typography color={'text.secondary'} sx={{ fontSize:'16px', fontFamily:'Inter, sans-serif', textAlign: 'center', padding:'20px' }}>There is no notification as of now</Typography>}
                    </Popover>
                </Box>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
