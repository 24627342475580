//@ts-nocheck
import React from "react";

import {
    Box,
    Button,
    Typography,
    Avatar,
    Divider
    // Customizable Area Start
    // Customizable Area End  
} from "@mui/material";

// Customizable Area Start
import RoomIcon from '@mui/icons-material/Room';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import StarIcon from '@mui/icons-material/Star';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import SellerProfileDescriptionController, { Props } from "./SellerProfileDescriptionController.web";
import {AppHeader} from "../../../components/src/AppHeader";

export default class SellerProfileDescription extends SellerProfileDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    toggleExpand = () => {
        this.setState((prevState) => ({
          isExpanded: !prevState.isExpanded,
        }));
    };

    renderProductDetail = () => {
        const { productDetail, isExpanded } = this.state
        return (
            <Box sx={{ width: '654px', mt: '10px', p: 2 }}>
            <Box display="flex" gap={2}>
                <Avatar alt="Naren" src={productDetail?.images?.[0]?.url} sx={{ width: '132px', height: '132px', mt: '5px' }} />
                <Box sx={{ paddingLeft: '75px' }}>
                    <Typography sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '26px', color: '#0F172A', fontFamily: 'Inter,sans-serif' }}>{productDetail?.business_name}</Typography>
                    <Box display="flex" alignItems="center" sx={{ color: '#64748B', fontSize: '14px', mt: '10px' }}>
                        <RoomIcon sx={{ fontSize: '18px', mr: '4px' }} />
                        <Typography sx={{ fontFamily: 'Inter,sans-serif' }}>New York, USA</Typography>
                    </Box>
                    <Box sx={{ mt: '12px', marginBottom: '10px' }}>
                        <Typography
                            data-test-id="toggle-description"
                            sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#4A5568',
                            fontFamily: 'Inter, sans-serif',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: isExpanded ? 'none' : 3,
                            WebkitBoxOrient: 'vertical',
                            cursor: 'pointer',
                            }}
                            onClick={this.toggleExpand}
                        >
                            {productDetail.product_feature}
                        </Typography>
                    </Box>
                    <Divider style={{ marginBottom: '10px', borderColor: '#E2E8F0' }} />
                    <Box display="flex" alignItems="center" sx={{ fontSize: '14px', color: '#64748B' }}>
                        <StarIcon sx={{ color: '#FBBF24', fontSize: '16px', mr: '4px', width: 24, height: 24 }} />
                        <Typography sx={{ fontFamily: 'Inter,sans-serif' }}>{productDetail?.average_rating}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
        )
    }

    navigationMenuProps = {
        id:"NavigationMenu",
        navigation: this.props.navigation,
      }
    // Customizable Area End

    render() {
        const { productList, reviewsList } = this.state
        return (
            // Customizable Area Start
            <>           
                <NavigationMenu {...this.navigationMenuProps} cartCount={this.state.cartCount}/>
                <Box sx={{ margin: '25px 0 0 290px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <NavigateBeforeOutlinedIcon sx={{ width: '24px', height: '24px', color: '#64748B', cursor: 'pointer' }} /><span onClick={this.handleBackButton} style={{ lineHeight: '24px', fontSize: '16px', fontWeight: 400, fontFamily: 'Inter,sans-serif', color: '#475569', cursor: 'pointer' }}>Back to Product</span>
                    </Box>
                    {this.renderProductDetail()}
                    <Box sx={{ width: '900px', display: 'flex', justifyContent: 'space-between', mb: 3 }}>

                        <Typography sx={{ fontSize: '20px', lineHeight: '28px', fontWeight: 700, fontFamily: 'Inter,sans-serif', mt: 2 }}>All products</Typography>

                        <Button onClick={this.handleViewProductButton} sx={{ backgroundColor: '#2d732d', '&:hover': { backgroundColor: '#2d732d' }, color: '#fff', width: 'auto', height: 'auto', fontSize: '11px', fontWeight: 400, lineHeight: '22px', textWrap: 'nowrap', marginTop: '20px', borderRadius: '98px', padding: '6px 18px 6px 18px' }}>view all products</Button>

                    </Box>

                    {
                        productList?.map((item: any, index: number) => {
                            return (
                                <div key={index}>
                                    <Box sx={{ width: '928px', display: 'flex', justifyContent: 'space-between', padding: '8px' }}>

                                        <Box sx={{ display: 'flex' }}>
                                            <Avatar alt="spoon" src={item.image_url} sx={{ width: '108px', height: '86px', borderRadius: '11px' }} variant="rounded" />
                                            <Box display={'inline-block'}>
                                                <small style={{ display: 'flex', fontSize: '14px', margin: '8px 0 0 10px', lineHeight: '22px', fontWeight: 400 }}>{item.title} <span style={{ color: "#059669", marginLeft: '5px', fontSize: '14px', lineHeight: '22px', fontWeight: 400, }}>({item.sub_title} stock)</span></small>
                                                <Typography sx={{ fontSize: '18px', margin: '6px 0 3px 10px', color: '#334155', fontWeight: 700, lineHeight: '26px' }}>${item.price} USD</Typography>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '22px', display: 'flex' }}><StarIcon style={{ color: '#FBBF24', width: '17.11px', height: '16.36px', marginLeft: '10px' }} />{item.rating}</Typography>
                                            </Box>
                                        </Box>
                                        <Button onClick={() => this.handleProductNavigation(item.product_id)} sx={{ textTransform: 'none', backgroundColor: '#CCE4D5', color: '#067B3E', width: '105px', height: '32px', padding: '6px 18px 6px 18px', fontSize: '14px', fontWeight: 400, lineHeight: '22px', textWrap: 'nowrap', marginTop: '20px', mr: '60px', '&:hover': { backgroundColor: '#CCE4D5', color: '#067B3E' } }}>view detail</Button>
                                    </Box>
                                    <Divider style={{ margin: '10px', width: '928px', borderColor: '#E2E8F0' }} />
                                </div>
                            )
                        })
                    }
                    <Box sx={{ width: '928px', mt: 5 }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, fontFamily: 'Inter,sans-serif', marginBottom: '20px' }}>Customer Review</Typography>
                        {
                            reviewsList?.map((item: any, index: number) => {
                                return (
                                    index === 0 ?
                                        <>
                                            <Box sx={{ width: '862px', display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Avatar alt="spoon" src={item.photo} sx={{ width: '42px', height: '42px', marginRight: '10px' }} />
                                                    <Box sx={{ display: 'inline-block' }}>
                                                        <Typography>{item.contact_name}</Typography>
                                                        <small>{item.time_passed}</small>
                                                    </Box>
                                                </Box>

                                                <Typography sx={{ fontSize: '14px', display: 'flex' }}><StarIcon style={{ color: '#FBBF24', width: '17.11px', height: '16.36px' }} />{item.rating}</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Inter,sans-serif', lineHeight: '24px', textWrap: 'wrap', mb: '20px', width: '865px' }}>{item.comment}</Typography>
                                        </>
                                        :
                                        <>
                                            <Box sx={{ width: '862px', display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Avatar alt="Naren" src={item.photo} sx={{ width: '42px', height: '42px', marginRight: '10px' }} />
                                                    <Box sx={{ display: 'inline-block' }}>
                                                        <Typography>{item.contact_name}</Typography>
                                                        <small>{item.time_passed}</small>
                                                    </Box>
                                                </Box>

                                                <Typography sx={{ fontSize: '14px', display: 'flex' }}><StarIcon style={{ color: '#FBBF24', width: '17.11px', height: '16.36px' }} />{item.rating}</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Inter,sans-serif', lineHeight: '18px', marginBottom: '40px', width: '829px' }}>{item.comment}</Typography>

                                        </>
                                )
                            })
                        }
                    </Box>
                </Box>
            </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
