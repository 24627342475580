import React from "react";
// Customizable Area Start
import {
  StyleSheet,
 
  SafeAreaView,
 
} from "react-native";
import {Grid, Box,
  Button,
  Typography,
  Rating, TextField, Divider, Avatar, CircularProgress,
  Select,
  MenuItem, FormControl} from "@mui/material"
import { AppHeader } from "../../../components/src/AppHeader.web";
import {prdImg} from './assets'
import {Star} from '@mui/icons-material';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import FilteritemsController, {
  Props,
} from "./FilteritemsController";
import { bold } from "@uiw/react-md-editor";
import { isArray } from "lodash";

export default class ProductDiscription extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount(){
    let id = new URLSearchParams(window.location.search).get('navigationBarTitleText')?.split('/')[0];
    this.getProductDetails(+`${id}`)
    this.getCartOrders()
      
  }
  isBuyerCanWriteReview = new URLSearchParams(window.location.search).get('navigationBarTitleText')?.includes("writeReview")
  isBuyerAccount= JSON.parse(localStorage.getItem('user') || '{}')?.attributes?.type === "BuyerAccount"
  // Customizable Area End

  render() {
    const images = this.state.selectedProduct?.attributes?.images || [];  
    const quantityArray = Array.from({ 
      length: Number(this.state.selectedProduct?.attributes.available_quantity) }, (_, i) => i + 1);  
     const navigationMenuProps = {
        id:"NavigationMenu",
        navigation:this.props.navigation
      }
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>
        {/* Customizable Area Start */}
        <NavigationMenu {...navigationMenuProps} cartCount={this.state.cartCount}/>
        <Box sx={{ maxWidth: '75%', margin: '0 auto', padding: '20px' }}>
        {/* Back to Products Link */}
        <Typography
          data-test-id='backbtn'
          variant="body1"
          onClick={()=>this.handleBackProductsBtnClick()}
          sx={{ fontSize: '16px',color:'#9a9ea8', fontFamily:'inter',fontWeight:500, marginBottom: '20px', cursor: 'pointer' }}
        >
          <span style={{fontFamily:'inter', fontWeight:'bold'}}>❮</span> Back to products
        </Typography>
        <Box sx={{ display: 'flex', gap: '40px' , justifyContent:'space-between' , alignItems:'center' }}>
         
         
            <Box sx={{ width: '50%' }}>
              <div style={{ display: 'flex', gap: '16px', justifyContent: 'center', alignItems: 'center' }}>
                <span onClick={this.handlePrevious} data-test-id="span_btn_first" style={{ cursor: 'pointer', display: images.length > 1 ? "block" : "none"}}>❮</span>
                <img
                  src={images[this.state.selectedImageIndex2]?.url || prdImg.default}
                  alt="Product Main"
                  style={{
                    width: '100%',
                    minWidth: '292px',
                    objectFit: 'cover',
                    height: '382px',
                    maxWidth: '292px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                  }}
                />
                <span onClick={this.handleNext} data-test-id="span_btn_second" style={{ cursor: 'pointer', display: images.length > 1 ? "block" : "none"}}>❯</span>
              </div>

              <Grid container spacing={1} sx={{ px: '32px', pl: '0px', margin: '-4px' }}>
                {images.map((image, index) => (
                  <Grid item xs={4} sx={{ px: 0, m: 0 }} key={index}>
                    <img
                      src={image.url}
                      alt={`Thumbnail ${index + 1}`}
                      data-test-id="img_thumbnail"
                      onClick={() => this.handleThumbnailClick(index)}
                      style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        border: index === this.state.selectedImageIndex2 ? '2px solid blue' : 'none',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          
          <Box sx={{display:'flex', alignItems:'flex-start', alignSelf:'start'}}>
          <Box sx={{   minWidth:'436px', alignItems:'start'}}>
            <Typography variant="h5" sx={{ fontWeight: '700', textTransform: "uppercase",fontFamily:'inter', fontSize: '18px', color: "rgba(15, 23, 42, 1)" }}>
              {this.state.selectedProduct?.attributes.name}
            </Typography>
            
            <Typography variant="body2" sx={{ fontSize: '14px',color:'#0F172A', fontFamily:'inter', marginBottom: '16px', fontWeight: 500 }}>
              ({this.state.selectedProduct?.attributes.type})
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', fontFamily:'inter',color: '#9399a2', marginBottom: '20px',fontWeight: "bold" }}>
            {this.state.selectedProduct?.attributes.product_feature}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '24px', fontFamily:'inter',color: '#334155', fontWeight: '700', marginBottom: '20px' }}>
              ${this.state.selectedProduct?.attributes.price} USD 
            </Typography>
            <Box sx={{ display:"flex" ,justifyContent:"space-between", borderTop:'1px solid #E2E8F0'}}>
            <Box sx={{ display: 'flex', paddingTop:'20px',flexDirection:'column', fontFamily:'inter',alignItems: 'flex-start', marginBottom: '20px' }}>
            
             <Typography variant="body1" sx={{ fontSize: '16px' , fontFamily:'inter',color: '#44403C'}}>
                Breeder : {(this.state.selectedProduct?.attributes as any)?.business_name}
              </Typography>
          
          </Box>
          <span style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "5px", color:'#64748B', fontWeight: 500, marginRight :"30px" }}>
              <><Star style={{ fill: '#FBBF24' }} />
              <span style={{color: "#334155", fontFamily: "Inter", fontSize: "14px", fontWeight:400}}>{this.state.selectedProduct?.attributes.average_rating}</span></>
            </span>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center',borderTop:'1px solid #E2E8F0', marginBottom: '20px' }}>
            </Box>
            <Box data-test-id="box1" sx={{ display: this.isBuyerAccount ? 'flex': "none",justifyContent:'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Typography variant="body1" sx={{ fontSize: '14px',color:'#9399a2', fontFamily:'inter', marginRight: '10px',fontWeight: "bold" }}>
                Qty:
              </Typography>
              
              <Typography variant="body1" sx={{ fontSize: '14px',color:'#9399a2', fontFamily:'inter', marginLeft: '20px', fontWeight: "bold" }}>
                Available Qty:  {this.state.selectedProduct?.attributes.available_quantity}
              </Typography>
            </Box>
           {this.isBuyerAccount && <> 
                  <FormControl variant="outlined" size="small">
                    <Select
                      value={this.state.addToCartCount}
                      data-test-id="cart-quantity-select"
                      displayEmpty
                      onChange={(e) => this.handleQuantityChange(Number(e.target.value))}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: 60,
                        padding: "4px 8px",
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#CBD5E1',
                          borderRadius: '8px'
                        },
                        '& .MuiSelect-select': {
                          display: 'flex !important',
                          alignItems: 'center !important',
                          paddingTop: '10px !important',
                          paddingBottom: '10px !important',
                          paddingLeft: "8px !important",
                          paddingRight: "45px !important",
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#0F172A',
                          right: "8px",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflow: 'auto',
                          },
                        },
                      }}
                    >
                      {quantityArray.map((value: any) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              <Button
              variant="contained"
              data-test-id="handleAddToCart"
              sx={{ fontSize: '16px',display:'block',textTransform: 'none',width:'197px', marginTop:'36px', padding: '10px', fontWeight: 700, background:"#067B3E", height:"56px", borderRadius :"8px" }}
              onClick={()=>this.handleAddToCart()}
            >
                {this.state.addToCartLoading ? <CircularProgress size="27px" color="inherit" /> : "Add to cart"}

            </Button> </>}
          </Box>
          </Box>
        </Box>

           {/* Product Detail */}
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h6" sx={{ fontSize: '20px',color: '#1E293B',fontFamily:'inter', fontWeight: '700', marginBottom: '20px' }}>
          Product Detail
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' ,fontFamily:'inter',}}>
        {this.state.selectedProduct?.attributes.specifications_attributes?.map(item=>{
              return <Typography variant="body1" sx={{ fontSize: '16px' ,fontFamily:'inter'}}>
               <span style={{display:'flex' ,justifyContent:'flex-start', width: "auto"}}>
               <span style={{width: "25%",fontWeight: 500, color:"rgba(15, 23, 42, 1)"}}> {item.key}</span>  <span style={{marginLeft:'48px',color:'#475569', fontWeight: 400}}>{item.value}</span>
                </span>
                </Typography>
            })}
          
        </Box>
      </Box>
       
        {/* Dealer Description */}
      <Box sx={{ marginTop: '40px' ,width:'624px'}}>
        <Typography variant="h6" sx={{ fontSize: '20px',color: '#1E293B',
          fontFamily:'inter', fontWeight: '700', marginBottom: '20px' }}>
          Seller Description
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '16px',fontWeight:400,fontFamily:'inter', marginBottom: '20px' ,color: '#64748B'}}>
        {this.state.selectedProduct?.attributes.description} &nbsp;
        <Typography
          variant="body2"
          component='span'
          sx={{ fontSize: '14px',fontWeight:400,fontFamily:'inter',color: '#007BFF', cursor: 'pointer' }}
          data-test-id="viewProfileBtn"
          onClick={()=>this.handleProfileNavigation(this.isBuyerAccount,this.state.selectedProduct?.id)}
        >
          View profile
        </Typography>
        </Typography>
       
      </Box>

          <Box sx={{ mx: 'auto', mt: 4 }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ mb:2,color: '#1E293B'}}>
              Customer Review
            </Typography>
            <Box
              sx={{
                mb: 3.5,
                display: (this.isBuyerAccount && this.isBuyerCanWriteReview) ? 'block' : "none",
              }}
            >
              <Typography variant="subtitle1" fontFamily="Inter" gutterBottom fontWeight="700">
                Give us your rating
              </Typography>
              <Rating
                name="user-rating"
                value={this.state.rating}
                onChange={this.handleRatingChange}
                sx={{ mb: 2, fontSize: "30px", gap: "20px" }}
              />
              <Box display="flex" justifyContent="space-between" gap="16px" width="98%" height="44px" alignItems="center">
                <TextField
                  placeholder="Help us to improve our service. Share your valuable comments."
                  variant="filled"
                  fullWidth
                  multiline
                  data-test-id="comment-input"
                  value={this.state.comment}
                  onChange={this.handleCommentChange}
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': { fontStyle: 'italic' },
                      height: "44px", background: "#EFF6FF"
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={() => this.handleSubmitReview()}
                  disabled={!this.state.comment.trim() || !this.state.rating}
                  data-test-id="handle_submit"
                  sx={{
                    fontFamily: "Inter", borderRadius: "25px", background: "#F5F5F4", width: "200px", height: "44px", border: "none", color: "#57534E", fontWeight: 700, textTransform: "none", cursor: "pointer",
                    "&:hover": {
                      background: "#E0E0DF",
                      border: "none",
                    }
                  }}
                >
                  {this.state.isReviewSubmitLoading ? <CircularProgress size="24px" /> : "Post review"}
                </Button>
              </Box>
            </Box>
            <Typography sx={{display: this.renderNoCommentsText(this.state.selectedProduct?.attributes?.comments_and_ratings) ? "block" : "none", color: "#94A3B8"}}>No comment yet</Typography>
            {this.state.selectedProduct?.attributes?.comments_and_ratings?.map((review:any) => (
              <Box key={review.id} sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={review.photo} alt={review.name} sx={{ mr: 2 }} />
                  <Box>
                    <Typography variant="body1" sx={{fontWeight: 500, color:"#334155"}}>
                      {review.contact_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {review.time_passed}
                    </Typography>
                  </Box>
                  <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: "10px", mr: 1 }}>
                    <Star style={{ fill: '#FBBF24' }} />
                    <Typography variant="body2" sx={{ mt: 0.5, color: "#334155", fontWeight: 500 }}>
                      {review.rating}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3, mt: 3, color: "#1E293B", fontWeight: 500 }}>
                  {review.comment}
                </Typography>
                <Divider />
              </Box>
            ))}
          </Box>
    </Box>
      
        {/* Customizable Area End */}
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100vw",
    backgroundColor: "#ffffffff"
  },

});
// Customizable Area End
