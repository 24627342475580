import React from 'react'
        
import { StyleSheet, Text, View , Image, TextInput , TouchableOpacity} from 'react-native'

const  Logo = require('./cannagenLogo.svg')
import { Typography } from "@mui/material";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Notification from '../../blocks/navigationmenu/src/Notification.web';
export function AppHeader({navigtion}:{navigtion:any}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    // navigtion.navigate('Notification')
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

     return (
      <>
      <View style={{flexDirection:'column'}}>
        <Notification open={open} id={id || ''} anchorEl={anchorEl} setAnchorEl={setAnchorEl} navigtion={navigtion}/>
    <View style={styles.headerContainer}>
      {/* <Logo  style={styles.logo}/> */}
      <Image source={Logo.default}  style={styles.logo} /> 
    
      {/* Location Section */}
      <View style={styles.locationContainer}>
      <FmdGoodOutlinedIcon sx={{fontSize:'16px'}} width={'24px'} height={'24px'}/>  <Typography style={styles.profileText}>{user?.attributes.state.toString()}</Typography>
      </View>

      {/* Search Section */}
      <View style={styles.searchContainer}>
       
        <TextInput
          style={styles.searchInput}
          placeholder="Search"
          placeholderTextColor="#ccc"
        />
      </View>
      {/* Notification & Cart Icons */}
      <View style={styles.iconsContainer}>
      <ShoppingCartOutlinedIcon fontSize={'small'}  sx={{mr:'20px', fontSize:'16px'}} onClick={() => navigtion.navigate('ShoppingCartOrders')}/>
      <NotificationsNoneOutlinedIcon aria-describedby={id} fontSize={'small'} sx={{mr:'20px', fontSize:'16px'}} onClick={handleClick}/>
      </View>

      {/* User Profile Section */}
      <View style={styles.profileContainer}>
      {user?.attributes?.photo_url ?  <Image
          source={{  uri: user?.attributes?.photo_url.toString()||"" }}
          style={styles.profileImage}
          
        />
        : <View style={{...styles.profileImage, borderWidth: 1, borderColor: 'black' ,borderStyle:"solid", backgroundColor: '#808080'}} ></View>}
        <View style={{flexDirection: 'column'}}>
        <Typography style={styles.profileText} sx={{
  fontWeight: 400,}}>{user?.attributes?.contact_name.toString()}</Typography>
        <TouchableOpacity>
          <Typography style={styles.switchAccountText}>Switch account</Typography>
        </TouchableOpacity>
        </View>
        
      </View>
      
    </View>
           <View style={{ flexWrap: 'nowrap', flexDirection: 'row', height: '32px', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1E293B', width: '100%' }}>
             <span style={{ cursor: 'pointer', }}>
               <Text style={{ color: '#ffff', }}><p style={{ textDecoration: 'none', color: '#ffff', cursor: 'pointer', fontFamily: 'Inter', fontSize: '14px', marginRight: '90px' }} onClick={() => navigtion.navigate('Settings2')}>Profile</p></Text>
             </span>
             <span style={{ cursor: 'pointer', }}>
               <Text style={{ color: '#ffff' }}><p style={{ textDecoration: 'none', color: '#ffff', fontFamily: 'Inter', fontSize: '14px', cursor: 'pointer' }} onClick={() => navigtion.navigate('Filteritems')}>Product</p></Text>
             </span>
           </View>
    </View>
    
  </>
  );
}

const styles = StyleSheet.create({
headerContainer: {
  flexDirection: 'row',
  alignItems: 'center',
  paddingHorizontal: 10,
  paddingVertical: 16,
  borderBottomWidth: 1,
  borderColor: '#ddd',
  maxHeight:'60px',
  justifyContent:'space-between'
},
logo: {
  width: 40,
  height: 40,
  resizeMode: 'contain',
},
locationContainer: {
  marginLeft: '79px',
  display:'flex',
  fontSize:16,
  flexDirection:'row'

  
},
locationText: {
  fontSize: 14,
  fontFamily: 'Inter san-sarif',
  lineHeight: 22,
},
searchContainer: {
  flex: 1,
  marginHorizontal: 15,
  marginRight:200,
  maxWidth:'540px',
  height:'44px'
},
searchInput: {
  backgroundColor: '#fff',
  borderRadius: 20,
  paddingHorizontal: 10,
  borderWidth: 1,
  borderColor: '#ddd',
  paddingVertical : 11,
},
iconsContainer: {
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft:'20px',
},
icon: {
  width: 14,
  height: 14,
  marginHorizontal: 10,
  resizeMode: 'contain',
  marginLeft:'32px'
},
profileContainer: {
  flexDirection: 'row',
  alignItems: 'center',
},
profileImage: {
  width: 30,
  height: 30,
  borderRadius: 15,
  resizeMode: 'contain',
},
profileText: {
  marginLeft: 5,
  fontSize: 14,
  color : '#0F172A',
  fontFamily:'inter'
},
switchAccountText: {
  marginLeft: 5,
  color: '#007bff',
  fontSize: 12,
},
});

export default AppHeader;