import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigtion?: any;
    open: boolean;
    id: string;
    anchorEl: HTMLButtonElement | null;
    setAnchorEl?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: HTMLButtonElement | null;
    isNotifyButtonClicked: boolean;
    notificationList: any
    expanded: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NotificationController extends BlockComponent<Props, S, SS> {
    [x: string]: any;
    // Customizable Area Start
    NotifyApiCallId: string = '';
    notificationDetailsApiCallId: any = '';
    readNotificationApiCallId: any = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null,
            isNotifyButtonClicked: false,
            notificationList: [],
            expanded: '',
        }
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async componentDidMount() {
        console.log('fkjdfkjdklfhj')
        super.componentDidMount();
        await this.getNotification();
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallId) {
                this.notificationResponse(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End        
    }

    // Customizable Area Start

   async handleNotificationClick(id: string) {
        this?.props?.navigtion?.navigate("Settings2", {})
        /*istanbul ignore next*/
        if(this.props.setAnchorEl){
            this.props.setAnchorEl(false);
        }
       await this.updateData(id);
       await this.getNotification();
    }

    async getNotification() {
        const token = await getStorageData('authToken');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.NotifyApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getNotificationAPIEndPoint}`
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async checkingNotification(id: string | null) {
        const token = await getStorageData('authToken');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.notificationDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getNotificationAPIEndPoint}/${id}`
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    notificationResponse = (apiRequestCallId: any, responseJson: any) => {
        /*istanbul ignore next*/
        if (apiRequestCallId === this.NotifyApiCallId) {
            if (responseJson.meta) {
                this.setState({ notificationList: responseJson?.data })
            }
        }
    }

    updateData = async (id:string) => {
        const token = await getStorageData('authToken');
        const headers = {

            "Content-Type": `${configJSON.validationApiContentType}`,
            token: token,

        };

        const readNotificationMsg = new Message(

            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.readNotificationApiCallId = readNotificationMsg.messageId;

        readNotificationMsg.addData(

            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.postNotificationAPIEndPoint}/${id}`
        );

        readNotificationMsg.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(headers)

        );

        readNotificationMsg.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.putApiMethod

        );

        runEngine.sendMessage(readNotificationMsg.id, readNotificationMsg);
    }

    handleCloseNotify = ()=> {
        /*istanbul ignore next*/
        if(this.props.setAnchorEl){
            this.props.setAnchorEl(false);
        }
      };

    handleToggle = (id: string) => {
        if(id === this.state.expanded) {
            this.setState({expanded: ''})
        } else {
            this.setState({expanded: id})
        }
    }
    // Customizable Area End
}
